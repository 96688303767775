@import "./colors.scss";
@import "./mixins";

// base
@import "./design_base";

// others
@import "./common.scss";
@import "./components/box.scss";
@import "./components/hero.scss";
@import "./components/concept_hero.scss";
@import "./components/concept_about.scss";
@import "./components/experience.scss";
@import "./components/skills.scss";
@import "./components/contact.scss";
@import "./components/footer.scss";

// utils